<template>
  <div class="auth">
    <h1 class="title title--big title--center title--indent">
      Восстановление пароля
    </h1>
    <p class="auth__desc">
      Введите адрес электронной почты, который привязан к аккаунту. Вы отправим на него ссылку, по которой вы сможете
      восстановить пароль
    </p>
    <form class="auth__form" @submit.prevent="checkForm">
      <div class="form-group">
        <label for="email">Email <sup>*</sup></label>
        <div class="form-control" :class="{ 'form-control--error': $v.email.$error }">
          <input
            id="email"
            type="email"
            class="form-control__input"
            name="email"
            placeholder="mail@example.com"
            v-model.trim="email"
            @change="$v.email.$touch()"
          />
        </div>
        <div class="form-group__error" v-if="$v.email.$dirty && !$v.email.required">Обязательное поле</div>
        <div class="form-group__error" v-if="$v.email.$dirty && !$v.email.email">
          Введите правильный формат почты
        </div>
      </div>
      <div class="form-group">
        <div class="form-group__place form-group__place--between">
          <router-link
            :to="{ name: 'partner-login' }"
            class="auth__link auth__link--indent auth__link--ordered"
          >
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
            </svg>
            <span>Назад</span>
          </router-link>
          <button
            type="submit"
            class="button"
            ref="submit"
            :disabled="$v.email.$error"
          >
            <span>Восстановить пароль</span>
            <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';

export default {
  name: 'PasswordRecovery',
  mixins: [ validationMixin ],
  data() {
    return {
      email: ''
    };
  },
  validations: {
    email: { required, email }
  },
  methods: {
    checkForm () {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.sendForm();
      }
    },
    sendForm() {
      this.$refs.submit.classList.add('preload');
      this.$store.dispatch('partner/auth/POST_RECOVERY', {email: this.email})
        .then(() => {
          this.$refs.submit.classList.remove('preload');
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Ссылка для восстановления пароля отправлена на Ваш адрес электронной почты'
          });
          this.$router.push({name: 'partner-login'});
        })
        .catch(() => {
          this.$refs.submit.classList.remove('preload');
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: 'С этим адресом электронной почты не связан ни один активный пользователь или пароль нельзя изменить'
          });
        });
    },
  }
};
</script>
